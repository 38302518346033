import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Labeled, { LabeledFieldProps } from "../Labeled";
import SelectField from "../SelectField";
import Currency from "@mapmycustomers/shared/types/Currency";
import MonetaryValue from "@mapmycustomers/shared/types/customField/MonetaryValue";
import ErrorRow from "../ErrorRow";
import { useConfigProvider } from "../../ConfigProvider";
import { bem } from "@react-md/utils";
import cn from "classnames";
import NumberField, { NumberFieldProps } from "../NumberField";
import type { OptionalFields } from "@mapmycustomers/shared";
import { isDefined } from "@mapmycustomers/shared/util/assert";

export interface MonetaryFieldProps
  extends Omit<LabeledFieldProps, "children" | "suffix">,
    Pick<NumberFieldProps, "min" | "max" | "precision"> {
  allowEmpty?: boolean;
  caption?: string;
  className?: string;
  currencies?: Currency[];
  currencyId?: Currency["id"];
  disabled?: boolean;
  error?: ReactNode;
  onChange?: (
    // the value is optional only when allowEmpty prop is true,
    // otherwise, a fully defined MonetaryValue will be sent
    value: MonetaryValue | OptionalFields<MonetaryValue, "value">
  ) => void;
  placeholder?: string;
  readOnly?: boolean;
  value?: MonetaryValue;
}

const block = bem("mmc-monetary-field");

const MonetaryField: React.FC<MonetaryFieldProps> = ({
  allowEmpty,
  caption,
  className,
  currencies,
  currencyId,
  disabled,
  error,
  label,
  labelClassName,
  labelPosition,
  max,
  min,
  onChange,
  placeholder,
  precision,
  readOnly,
  required,
  rowProps,
  sideLabelSpan,
  value,
}) => {
  const configProvider = useConfigProvider();
  const currencyIdValue = currencyId ?? configProvider.defaultCurrencyCode;
  const [amount, setAmount] = useState<number | undefined>(value?.value);

  const handleChange = useCallback(
    (currencyId?: Currency["id"], amount?: number) => {
      if (currencyId && onChange && (allowEmpty || isDefined(amount))) {
        onChange({ currencyId, value: amount });
      }
    },
    [allowEmpty, onChange]
  );

  const handleCurrencyChange = useCallback(
    (currencyId: Currency["id"]) => {
      handleChange(currencyId, amount);
    },
    [amount, handleChange]
  );

  const handleAmountChange = useCallback(
    (amount?: number) => {
      setAmount(amount);
      handleChange(currencyIdValue, amount);
    },
    [currencyIdValue, handleChange, setAmount]
  );

  const currencyOptions = useMemo(
    () =>
      currencies
        ? currencies.map(({ id, code }) => ({ label: code, value: id }))
        : configProvider.currencies.map(({ id, code }) => ({
            label: code,
            value: id,
          })),
    [configProvider.currencies, currencies]
  );

  const handleFilterOption = useCallback(
    (inputValue: string, option?: { label?: ReactNode }) => {
      return (
        (option?.label as string)
          ?.toLowerCase()
          .includes(inputValue.toLowerCase().trim()) ?? false
      );
    },
    []
  );

  useEffect(() => {
    setAmount(value?.value);
  }, [value?.currencyId, value?.value]);

  return (
    <Labeled
      className={cn(block({ disabled }), className)}
      label={label}
      labelClassName={cn(block("label"), labelClassName)}
      labelPosition={labelPosition}
      required={required}
      rowProps={rowProps}
      sideLabelSpan={sideLabelSpan}
    >
      <div className={block("input")}>
        <div className={block("monetary")}>
          <SelectField<Currency["id"]>
            className={block("currency-select")}
            disabled
            dropdownMatchSelectWidth={false}
            filterOption={handleFilterOption}
            onChange={handleCurrencyChange}
            options={currencyOptions}
            showSearch
            value={currencyIdValue}
          />
          <NumberField
            className={block("amount")}
            fullWidth
            locked={disabled}
            max={max}
            min={min}
            onChange={handleAmountChange}
            placeholder={placeholder}
            precision={precision ?? 2}
            readOnly={readOnly}
            value={amount}
          />
        </div>
        {error ? (
          <ErrorRow>{error}</ErrorRow>
        ) : caption ? (
          <div className={block("caption")}>{caption}</div>
        ) : null}
      </div>
    </Labeled>
  );
};

export default MonetaryField;
