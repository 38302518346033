import React, { useCallback, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import Form from "antd/es/form";
import { FormInstance } from "antd/es/form/hooks/useForm";
import Tooltip from "antd/es/tooltip";
import Switch from "antd/es/switch";
import FormValues from "../../types/FormValues";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons/faTimesCircle";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import styles from "./ActivityCompleted.module.scss";
import isValidDate from "util/isValidDate";
import { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";
import getCompletedValueBasedOnStartAt from "../../utils/getCompletedValueBasedOnStartAt";

interface Props {
  creating?: boolean;
  disabled?: boolean;
  form: FormInstance<FormValues>;
  onChange?: () => void;
  tooltip?: string;
}

const messages = defineMessages({
  done: {
    id: "createActivityModal.footer.scheduleActivity.done",
    defaultMessage: "Done!",
    description: "Done!",
  },
  markDone: {
    id: "createActivityModal.footer.scheduleActivity.markDone",
    defaultMessage: "Mark as Done",
    description: "Mark as Done",
  },
  markNotDone: {
    id: "createActivityModal.footer.scheduleActivity.markNotDone",
    defaultMessage: "Mark as Not Done",
    description: "Mark as Not Done",
  },
  notDone: {
    id: "createActivityModal.footer.scheduleActivity.notDone",
    defaultMessage: "Not done yet",
    description: "Not done yet",
  },
});

const ActivityCompleted: React.FC<Props> = ({ creating, disabled, form, onChange, tooltip }) => {
  const intl = useIntl();
  const [firstChange, setFirstChange] = useState(true);

  const dateValue = form.getFieldValue(ActivityFieldName.START_AT);

  const handleCompletedToggle = useCallback(() => {
    setFirstChange(false);
    onChange?.();
  }, [onChange, setFirstChange]);

  useEffect(() => {
    if (creating && firstChange && isValidDate(dateValue)) {
      const date = new Date(dateValue);
      const completed = getCompletedValueBasedOnStartAt(date);
      if (completed !== undefined && completed !== form.getFieldValue("completed")) {
        form.setFieldsValue({
          completed,
        });
        onChange?.();
      }
    }
  }, [creating, dateValue, firstChange, form, onChange]);

  return (
    <div className={styles.container}>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => prevValues.completed !== curValues.completed}
      >
        {({ getFieldValue, setFieldsValue }) => {
          const isCompleted = getFieldValue(ActivityFieldName.COMPLETED);
          return (
            <>
              <div className={styles.icon}>
                {isCompleted ? (
                  <FontAwesomeIcon className={styles.active} icon={faCheckCircle} />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} />
                )}
              </div>

              <div>
                <Tooltip
                  overlayClassName={styles.tooltip}
                  placement="top"
                  title={
                    tooltip ??
                    (disabled
                      ? undefined
                      : isCompleted
                      ? intl.formatMessage(messages.markNotDone)
                      : intl.formatMessage(messages.markDone))
                  }
                >
                  <div
                    className={styles.switch}
                    onClick={() => {
                      if (!disabled) {
                        setFieldsValue({ completed: !isCompleted });
                        handleCompletedToggle();
                      }
                    }}
                  >
                    <div className={styles.label}>
                      {isCompleted
                        ? intl.formatMessage(messages.done)
                        : intl.formatMessage(messages.notDone)}
                    </div>

                    <div onClick={stopEvents}>
                      <Form.Item name={ActivityFieldName.COMPLETED} noStyle valuePropName="checked">
                        <Switch disabled={disabled} onChange={handleCompletedToggle} size="small" />
                      </Form.Item>
                    </div>
                  </div>
                </Tooltip>
              </div>
            </>
          );
        }}
      </Form.Item>
    </div>
  );
};

export default ActivityCompleted;
