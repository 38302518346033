import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import UserSelectField from "component/input/UserSelectField";
import User from "@mapmycustomers/shared/types/User";
import { RootState } from "store/rootReducer";
import { getUsers, getUsersOfEntireOrganization } from "store/members";
import { userDisplayNameComparator } from "@mapmycustomers/shared/util/comparator";
import { connect } from "react-redux";
import styles from "./OwnerField.module.scss";

interface OwnProps {
  disabled?: boolean;
  onChange?: (ownerId?: User["id"]) => void;
  value?: User["id"];
  users?: User[];
}

interface Props extends OwnProps {
  allUsers: User[];
  users: User[];
}

const OwnerField: React.FC<Props> = ({ allUsers, disabled, onChange, users, value }) => {
  const intl = useIntl();

  const availableUsers = useMemo(() => {
    if (!value || users.some(({ id }) => id === value)) {
      return users;
    }
    const userValue = allUsers.find(({ id }) => id === value);
    return userValue ? [...users, userValue].sort(userDisplayNameComparator) : users;
  }, [allUsers, users, value]);

  const userFilterOption = useCallback(
    (inputValue: string, option: any) => {
      const user = availableUsers.find(({ id }) => id === parseInt(option?.value));
      return ((user?.fullName ?? "") + (user?.username ?? ""))
        .toLowerCase()
        .includes(inputValue.toLowerCase().trim());
    },
    [availableUsers]
  );

  const handleUserSelect = useCallback((userId: User["id"]) => onChange?.(userId), [onChange]);

  return (
    <UserSelectField
      className={styles.select}
      defaultUsersOption={false}
      disabled={disabled}
      dropdownMatchSelectWidth={false}
      filterOption={userFilterOption}
      label={false}
      onSelect={handleUserSelect}
      placeholder={intl.formatMessage({
        id: "formFields.ownerField.placeholder",
        defaultMessage: "Type to find user by name or email",
        description: "Placeholder of the Owner field",
      })}
      showSearch
      users={availableUsers}
      value={availableUsers.find(({ id }) => id === value)}
    />
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  allUsers: getUsersOfEntireOrganization(state),
  users: ownProps.users ?? getUsers(state),
});

export default connect(mapStateToProps, {})(OwnerField);
