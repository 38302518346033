import { useCallback, useEffect } from "react";
import { FormInstance } from "antd/es/form/Form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import FormValues from "../types/FormValues";
import { getActivityTypes } from "../../../../store/activity";
import { getAssociationsState } from "../store";

const useActivityNameAutoFill = (
  form: FormInstance<FormValues>,
  isActivityNameChangedByUser: boolean
) => {
  const intl = useIntl();

  const activityTypes = useSelector(getActivityTypes);
  const associations = useSelector(getAssociationsState);

  const nameFieldName: keyof FormValues = "name";
  const activityTypeIdFieldName: keyof FormValues = "activityTypeId";

  const autoFillActivityName = useCallback(() => {
    if (isActivityNameChangedByUser) {
      return;
    }

    const activityTypeId = form.getFieldValue(activityTypeIdFieldName);

    const activityTypeName = activityTypes.find(
      (activityType) => activityType.id === activityTypeId
    )?.name;

    const recordName =
      associations.associatedCompany?.name ||
      associations.associatedPerson?.name ||
      associations.associatedDeal?.name;

    let activityName: string;
    if (activityTypeName && recordName) {
      activityName = intl.formatMessage(
        {
          id: "createActivityModal.autoFillName",
          defaultMessage: "{activityTypeName} with {recordName}",
          description: "Default activity name",
        },
        {
          activityTypeName,
          recordName,
        }
      );
    } else if (activityTypeName) {
      activityName = activityTypeName;
    } else {
      activityName = "";
    }

    form.setFieldValue(nameFieldName, activityName);
  }, [activityTypes, associations, form, intl, isActivityNameChangedByUser]);

  useEffect(() => {
    autoFillActivityName();
  }, [activityTypes, associations, autoFillActivityName, form, intl]);

  const handleValuesChange = useCallback(
    (changedValues: Partial<FormValues>, _: FormValues) => {
      if (!isActivityNameChangedByUser && changedValues.activityTypeId) {
        autoFillActivityName();
      }
    },
    [autoFillActivityName, isActivityNameChangedByUser]
  );

  return [handleValuesChange];
};

export default useActivityNameAutoFill;
