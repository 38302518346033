import Color from "@mapmycustomers/shared/enum/Color";
import { GeoManagementState } from "@mapmycustomers/shared/types/base/Located";
import { Company } from "@mapmycustomers/shared/types/entity";
import User from "@mapmycustomers/shared/types/User";

const getInitialValues = (currentUserId: User["id"], initialValues?: Partial<Company>) => {
  const result = initialValues
    ? {
        color: Color.BLACK,
        name: initialValues.name,
        phone: initialValues.phone,
        website: initialValues.website,
        user: currentUserId,
      }
    : { user: currentUserId };
  return initialValues
    ? initialValues.address || initialValues.city || !initialValues.geoPoint
      ? {
          ...result,
          addressDetails: {
            address: initialValues.address,
            city: initialValues.city,
            region: initialValues.region,
            regionCode: initialValues.regionCode,
            postalCode: initialValues.postalCode,
            countryCode: initialValues.countryCode,
            geoManagementState: GeoManagementState.AUTOMATIC_PRESERVE_ADDRESS,
          },
        }
      : {
          ...result,
          longitude: initialValues.geoPoint.coordinates[0] ?? "",
          latitude: initialValues.geoPoint.coordinates[1] ?? "",
        }
    : result;
};

export default getInitialValues;
