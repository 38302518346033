import { all, call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  addCard,
  changeScope,
  fetchTimelineEvents,
  removeCard,
  saveCard,
  saveDashboardLayout,
  saveDashboardSettings,
} from "./actions";
import { getCurrentUser, getOrganizationId } from "store/iam";
import Organization from "@mapmycustomers/shared/types/Organization";
import { handleError } from "store/errors/actions";
import { callApi } from "store/api/callApi";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { activitiesLoggedSagas } from "scene/dashboard/store/cards/activitiesLogged/sagas";
import { activitiesOverdueSagas } from "scene/dashboard/store/cards/activitiesOverdue/sagas";
import { checkInsSagas } from "scene/dashboard/store/cards/checkIns/sagas";
import { newRecordsSagas } from "scene/dashboard/store/cards/newRecords/sagas";
import { noContactInSagas } from "scene/dashboard/store/cards/noContactIn/sagas";
import { outOfFrequencySagas } from "scene/dashboard/store/cards/outOfFrequency/sagas";
import { recordsPastDueSagas } from "scene/dashboard/store/cards/recordsPastDue/sagas";
import { stackRankSagas } from "./stackRank/sagas";
import Dashboard from "types/dashboard/Dashboard";
import ViewType from "scene/dashboard/enum/ViewType";
import invariant from "tiny-invariant";
import TimelineEvent from "types/events/TimelineEvent";
import { getBoards, getEventsFilters, getEventsTotal } from "scene/dashboard/store/selectors";
import { endOfDay } from "date-fns/esm";
import { EVENTS_PAGE_SIZE } from "scene/dashboard/store/const";
import EventFilters from "scene/dashboard/store/EventFilters";
import PlatformFilterModel, {
  Condition,
  PlatformFilterCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import getOrConditionByEventTypes from "scene/dashboard/store/getOrConditionByEventTypes";
import { getEventTypesByViewFilter } from "scene/dashboard/enum/TimelineViewFilter";
import dashboardSettings from "scene/dashboard/utils/dashboardSettings";
import { Action, isActionOf } from "typesafe-actions";
import DashboardCard from "types/dashboard/DashboardCard";
import getResultingScope from "scene/dashboard/store/utils/getResultingScope";
import Scope from "types/dashboard/Scope";
import { parseApiDate } from "util/parsers";
import isValidDate from "util/isValidDate";
import User from "@mapmycustomers/shared/types/User";
import { isMember } from "store/iam/util";
import TimelineEventType from "scene/dashboard/enum/TimelineEventType";
import TimelineAction from "enum/TimelineAction";

function* onChangeScope({ payload }: ReturnType<typeof changeScope.request>) {
  try {
    const { scope, view } = payload;
    const orgId: Organization["id"] = yield select(getOrganizationId);

    if (view === ViewType.DASHBOARD) {
      const isTeamUserScope = !!scope.teamId && !!scope.userId;
      const requests = [callApi("fetchDashboards", orgId, scope)];
      if (isTeamUserScope) {
        // also fetch team board, which is used as a root board for team members
        requests.push(callApi("fetchDashboards", orgId, { ...scope, userId: undefined }));
      }
      const responses: ListResponse<Dashboard>[] = yield all(requests);
      yield put(changeScope.success({ view, boards: responses.flatMap(({ data }) => data) }));
    } else if (view === ViewType.TIMELINE) {
      let oldestEventDate: Date | undefined = undefined;
      const $filters: PlatformFilterModel | undefined = yield getTimelineFilters(
        payload.scope,
        false,
        true
      );
      if ($filters) {
        const events: ListResponse<TimelineEvent> = yield callApi("fetchTimelineEvents", orgId, {
          $filters,
          // technically, we only need 1 event, but platform filters out events which are related to deleted
          // entities, so it's not guaranteed to return any results if we use $limit=1. For more info:
          // https://mapmycustomers.slack.com/archives/C05NSL22M0T/p1717154920444389?thread_ts=1717152649.150669&cid=C05NSL22M0T
          $limit: 100,
          $order: "updatedAt",
        });
        if (events.data.length) {
          const date = parseApiDate(events.data[0].updatedAt);
          if (isValidDate(date)) {
            oldestEventDate = date;
          }
        }
      }
      yield put(changeScope.success({ view, oldestEventDate }));
    } else {
      invariant(false, `Unexpected view type: ${view}`);
    }
  } catch (error) {
    yield handleError({ error });
    yield put(changeScope.failure());
  }
}

const isLoadMorePayload = (payload: ReturnType<typeof fetchTimelineEvents.request>["payload"]) =>
  !(payload.eventTypes || "query" in payload || "startDate" in payload || "viewFilter" in payload);

function* getTimelineFilters(scope: Scope, isLoadMoreRequest: boolean, ignoreStartDate?: boolean) {
  let $and: PlatformFilterCondition[] = [];
  if (scope.userId) {
    $and.push({ "updatedBy.id": { $in: [scope.userId] } });
  } else if (scope.teamId) {
    $and.push({ teamId: { $in: [scope.teamId] } });
  }

  const { eventTypes, offset, query, startDate, viewFilter }: EventFilters = yield select(
    getEventsFilters
  );
  const eventsTotal: number = yield select(getEventsTotal);

  if (startDate && !ignoreStartDate) {
    $and.push({ updatedAt: { $lte: endOfDay(startDate).toISOString() } });
  }

  if (query) {
    $and.push({ "entity.name": { $in: query } });
  }

  const viewFilterEventTypes = getEventTypesByViewFilter(viewFilter);
  // event types take precedence
  const finalEventTypes = viewFilterEventTypes.filter((type) => eventTypes.includes(type));

  let $or = getOrConditionByEventTypes(finalEventTypes);
  if (!$or.length || (isLoadMoreRequest && offset > eventsTotal)) {
    // nothing to fetch
    return undefined;
  }

  if (scope.userId && finalEventTypes.includes(TimelineEventType.MENTIONS)) {
    // we need a special handling, more info:
    // https://mapmycustomers.atlassian.net/browse/TART-17523?focusedCommentId=46849

    // We know that the item with an "action" key is present there due to getOrConditionByEventTypes
    // @ts-ignore
    const actions: Condition = $or.find((item) => item.action)!.action;
    // removing mentions from there, since we'll add them separately
    actions.$in = (actions.$in as TimelineAction[]).filter(
      (action) => action !== TimelineAction.MENTION
    );

    // We know that the item with an "updatedBy.id" key is present there
    // @ts-ignore
    const andForMentions = $and.filter((item) => !item["updatedBy.id"]);
    andForMentions.push({ "updatedBy.id": { $nin: [scope.userId] } });
    andForMentions.push({ action: { $in: [TimelineAction.MENTION] } });

    const $finalOr: PlatformFilterCondition[] = [];
    if ((actions.$in as TimelineAction[]).length) {
      $and.push({ $or });
      $finalOr.push({ $and });
    }
    $finalOr.push({ $and: andForMentions });

    $and = [];
    $or = $finalOr;
  }

  return { $and, $or };
}

function* onFetchTimelineEvents({ payload }: ReturnType<typeof fetchTimelineEvents.request>) {
  try {
    const isLoadMoreRequest = isLoadMorePayload(payload);

    if (payload.eventTypes) {
      yield call([dashboardSettings, dashboardSettings.setVisibleEventTypes], payload.eventTypes);
    }

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const { scope } = payload;

    const $filters: PlatformFilterModel | undefined = yield getTimelineFilters(
      scope,
      isLoadMoreRequest
    );
    if (!$filters) {
      // nothing to fetch
      yield put(
        fetchTimelineEvents.success({
          append: isLoadMoreRequest,
          events: [],
          total: !isLoadMoreRequest ? 0 : undefined,
        })
      );
      if (payload.onComplete) {
        yield call(payload.onComplete);
      }
      return;
    }

    const { offset }: EventFilters = yield select(getEventsFilters);

    const events: ListResponse<TimelineEvent> = yield callApi("fetchTimelineEvents", orgId, {
      $filters,
      $offset: offset,
      $limit: EVENTS_PAGE_SIZE,
      $order: "-updatedAt",
    });

    yield put(
      fetchTimelineEvents.success({
        append: isLoadMoreRequest,
        events: events.data,
        total: events.total,
      })
    );
    if (payload.onComplete) {
      yield call(payload.onComplete);
    }
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchTimelineEvents.failure(error));
  }
}

// TODO: consider combining all onAddCard, onSaveCard, onRemove card into one function because they're all the same
function* onAddCard({ payload }: ReturnType<typeof addCard.request>) {
  try {
    const scope = getResultingScope(payload.scope);
    const boards: Dashboard[] = yield select(getBoards);

    // this board is already updated in redux store and contains the new card
    const board = boards.find(
      (board) => board.teamId === scope.teamId && board.userId === scope.userId
    );

    if (!board) {
      yield put(addCard.failure());
      return;
    }

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const updatedBoard: Dashboard = yield callApi("updateDashboard", orgId, board);

    yield put(addCard.success(updatedBoard));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(addCard.failure());
  }
}

function* onSaveCard({ payload }: ReturnType<typeof saveCard.request>) {
  try {
    const { scope } = payload;
    const isTeamUserScope = !!scope.teamId && !!scope.userId;
    const boards: Dashboard[] = yield select(getBoards);

    const currentUser: User = yield select(getCurrentUser);

    // these boards are already updated in redux store and has the card removed
    // Note: not all these boards are necessarily affected, e.g. when team member board
    // can remain unchanged. But we still save all of them.
    const affectedBoards = boards.filter(
      (board) =>
        (board.teamId === scope.teamId && board.userId === scope.userId) ||
        (isTeamUserScope &&
          !isMember(currentUser) &&
          board.teamId === scope.teamId &&
          !board.userId)
    );

    if (!affectedBoards.length) {
      yield put(saveCard.failure());
      return;
    }

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const updatedBoards: Dashboard[] = yield all(
      affectedBoards.map((board) => callApi("updateDashboard", orgId, board))
    );

    yield put(saveCard.success(updatedBoards));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(saveCard.failure());
  }
}

function* onRemoveCard({ payload }: ReturnType<typeof removeCard.request>) {
  try {
    const { scope } = payload;
    const isTeamUserScope = !!scope.teamId && !!scope.userId;
    const boards: Dashboard[] = yield select(getBoards);

    // these boards are already updated in redux store and has the card removed
    // Note: not all these boards are necessarily affected, e.g. when team member board
    // can remain unchanged. But we still save all of them.
    const affectedBoards = boards.filter(
      (board) =>
        (board.teamId === scope.teamId && board.userId === scope.userId) ||
        (isTeamUserScope && board.teamId === scope.teamId && !board.userId)
    );

    if (!affectedBoards.length) {
      yield put(removeCard.failure());
      return;
    }

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const updatedBoards: Dashboard[] = yield all(
      affectedBoards.map((board) => callApi("updateDashboard", orgId, board))
    );

    yield put(removeCard.success(updatedBoards));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(removeCard.failure());
  }
}

function* onSaveDashboardLayout({ payload }: ReturnType<typeof saveDashboardLayout.request>) {
  try {
    const { cardPositions } = payload;
    const scope = getResultingScope(payload.scope);
    const boards: Dashboard[] = yield select(getBoards);

    const board = boards.find(
      (board) => board.teamId === scope.teamId && board.userId === scope.userId
    );

    if (!board) {
      yield put(saveDashboardLayout.failure());
      return;
    }

    const orgId: Organization["id"] = yield select(getOrganizationId);

    const boardPayload: Dashboard = {
      ...board,
      settings: {
        ...board.settings,
        cards: board.settings.cards.map((card: DashboardCard) => ({
          ...card,
          ...(cardPositions[String(card.id)] ?? {}),
        })),
      },
    };

    const updatedBoard: Dashboard = yield callApi("updateDashboard", orgId, boardPayload);

    yield put(saveDashboardLayout.success(updatedBoard));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(saveDashboardLayout.failure());
  }
}

function* onSaveDashboardSettings({ payload }: ReturnType<typeof saveDashboardSettings.request>) {
  try {
    const { settings, scope } = payload;

    const boards: Dashboard[] = yield select(getBoards);

    const board = boards.find(
      (board) => board.teamId === scope.teamId && board.userId === scope.userId
    );

    if (!board) {
      yield put(saveDashboardSettings.failure());
      return;
    }

    const orgId: Organization["id"] = yield select(getOrganizationId);

    const boardPayload: Dashboard = { ...board, settings: { ...board.settings, ...settings } };
    const updatedBoard: Dashboard = yield callApi("updateDashboard", orgId, boardPayload);
    yield put(saveDashboardSettings.success(updatedBoard));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(saveDashboardSettings.failure());
  }
}

const isLoadMoreAction = (action: Action) =>
  isActionOf(fetchTimelineEvents.request, action) && isLoadMorePayload(action.payload);
const isLoadNewAction = (action: Action) =>
  isActionOf(fetchTimelineEvents.request, action) && !isLoadMorePayload(action.payload);

export function* dashboardSaga() {
  yield fork(stackRankSagas);
  yield fork(activitiesLoggedSagas);
  yield fork(activitiesOverdueSagas);
  yield fork(checkInsSagas);
  yield fork(newRecordsSagas);
  yield fork(noContactInSagas);
  yield fork(outOfFrequencySagas);
  yield fork(recordsPastDueSagas);
  yield takeLatest(changeScope.request, onChangeScope);
  yield takeLatest(isLoadNewAction, onFetchTimelineEvents);
  yield takeEvery(isLoadMoreAction, onFetchTimelineEvents);
  yield takeEvery(addCard.request, onAddCard);
  yield takeEvery(saveCard.request, onSaveCard);
  yield takeEvery(removeCard.request, onRemoveCard);
  yield takeLatest(saveDashboardLayout.request, onSaveDashboardLayout);
  yield takeLatest(saveDashboardSettings.request, onSaveDashboardSettings);
}
