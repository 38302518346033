import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";

const personRecordState = (state: RootState) => state.component.personRecord;

export const getRecordData = createSelector(personRecordState, ({ recordData }) => recordData);

export const isLoading = createSelector(personRecordState, ({ loading }) => loading);

export const isDeleting = createSelector(personRecordState, ({ deleteLoading }) => deleteLoading);

export const isPersonUpdateLoading = createSelector(
  personRecordState,
  ({ personUpdateLoading }) => personUpdateLoading
);

export const isActivitiesLoading = createSelector(
  personRecordState,
  ({ activitiesLoading }) => activitiesLoading
);

export const isActivitiesCompletedByTypeLoading = createSelector(
  personRecordState,
  ({ activitiesCompletedByTypeLoading }) => activitiesCompletedByTypeLoading
);

export const isActivitiesTotalLoading = createSelector(
  personRecordState,
  ({ activitiesTotalLoading }) => activitiesTotalLoading
);

export const isAddPersonParentCompanyLoading = createSelector(
  personRecordState,
  ({ addPersonParentCompanyLoading }) => addPersonParentCompanyLoading
);

export const isAddPersonDealsLoading = createSelector(
  personRecordState,
  ({ addPersonDealsLoading }) => addPersonDealsLoading
);

export const isPersonRoutesLoading = createSelector(
  personRecordState,
  ({ personRoutesLoading }) => personRoutesLoading
);

export const isPersonNoteCreateLoading = createSelector(
  personRecordState,
  ({ personNoteCreateLoading }) => personNoteCreateLoading
);

export const areFilesUploading = createSelector(
  personRecordState,
  ({ filesUploading }) => filesUploading
);

export const getSelectedActivityTypes = createSelector(
  personRecordState,
  ({ activitiesSelectedActivityTypes }) => activitiesSelectedActivityTypes
);

export const getRecapRange = createSelector(
  personRecordState,
  ({ activitiesRecapRange }) => activitiesRecapRange
);

export const getActiveTab = createSelector(personRecordState, ({ activeTab }) => activeTab);

export const getActivitiesSearchQuery = createSelector(
  personRecordState,
  ({ activitiesSearchQuery }) => activitiesSearchQuery
);

export const getActivitiesFilter = createSelector(
  personRecordState,
  ({ activitiesFilter }) => activitiesFilter
);

export const getRemovePersonParentCompanyLoadingIds = createSelector(
  personRecordState,
  ({ removePersonParentCompanyLoadingIds }) => removePersonParentCompanyLoadingIds
);

export const getRemovePersonDealLoadingIds = createSelector(
  personRecordState,
  ({ removePersonDealLoadingIds }) => removePersonDealLoadingIds
);

export const getRemovePersonRouteLoadingsIds = createSelector(
  personRecordState,
  ({ removePersonRouteLoadingsIds }) => removePersonRouteLoadingsIds
);

export const getPersonNoteDeleteLoadingIds = createSelector(
  personRecordState,
  ({ personNoteDeleteLoadingIds }) => personNoteDeleteLoadingIds
);

export const getPersonNoteUpdateLoadingIds = createSelector(
  personRecordState,
  ({ personNoteUpdateLoadingIds }) => personNoteUpdateLoadingIds
);

export const getPostponeActivityLoadingIds = createSelector(
  personRecordState,
  ({ postponeActivityLoadingIds }) => postponeActivityLoadingIds
);

export const getToggleCompleteActivityLoadingIds = createSelector(
  personRecordState,
  ({ toggleCompleteActivityLoadingIds }) => toggleCompleteActivityLoadingIds
);

export const getUpdateActivityNoteLoadingIds = createSelector(
  personRecordState,
  ({ updateActivityNoteLoadingIds }) => updateActivityNoteLoadingIds
);

export const doesNeedToScrollToActivity = createSelector(
  personRecordState,
  ({ activityAutoScrolling }) => activityAutoScrolling
);

export const isPersonRecordChanged = createSelector(
  personRecordState,
  ({ hasChanges }) => hasChanges
);

export const getPerson = createSelector(getRecordData, ({ person }) => person);

export const getActivitiesOrder = createSelector(
  personRecordState,
  ({ activitiesOrder }) => activitiesOrder
);

export const getActivitiesSelectedAssignees = createSelector(
  personRecordState,
  ({ activitiesSelectedAssignees }) => activitiesSelectedAssignees
);
