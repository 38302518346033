import invariant from "tiny-invariant";
import IFieldModel from "@mapmycustomers/shared/types/fieldModel/IFieldModel";
import { SET_FILTER_OPERATOR } from "./convertFromAgGridFilterModel";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FilterModel, {
  FilterCondition,
  SimpleCondition,
} from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import CombineOperator from "@mapmycustomers/shared/types/viewModel/internalModel/CombineOperator";
import PlatformCombineOperator from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformCombineOperator";
import PlatformFilterModel, {
  AreaCondition,
  Condition,
  PlatformCombinedCondition,
  PlatformFilterCondition,
  PlatformSimpleCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import {
  isEmptyCondition,
  isInAreaCondition,
  isInRangeCondition,
  isIntervalCondition,
  isLegacyCondition,
  isMetaField,
} from "./util";
import parseCadenceStatusCondition from "./converters/parseCadenceStatusCondition";
import parseRottingDaysOutCondition from "./converters/parseRottingDaysOutCondition";
import parseRangeCondition from "./converters/parseRangeCondition";
import parseAreaCondition from "./converters/parseAreaCondition";
import parseEmptyCondition from "./converters/parseEmptyCondition";
import parseLegacyCondition from "./converters/parseLegacyCondition";
import parseIntervalCondition from "./converters/parseIntervalCondition";
import { isNotEmpty } from "../assert";

export const platformFilterOperatorToFilterOperatorMap: Partial<{
  [key in PlatformFilterOperator]: FilterOperator;
}> = {
  [PlatformFilterOperator.EQUALS]: FilterOperator.EQUALS,
  [PlatformFilterOperator.NOT_EQUAL]: FilterOperator.NOT_EQUAL,
  [PlatformFilterOperator.LESS_THAN]: FilterOperator.LESS_THAN,
  [PlatformFilterOperator.GREATER_THAN]: FilterOperator.GREATER_THAN,
  [PlatformFilterOperator.LESS_THAN_OR_EQUAL]: FilterOperator.LESS_THAN_OR_EQUAL,
  [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]: FilterOperator.GREATER_THAN_OR_EQUAL,
  [PlatformFilterOperator.CONTAINS]: FilterOperator.CONTAINS,
  [PlatformFilterOperator.NOT_CONTAINS]: FilterOperator.NOT_CONTAINS,
  [PlatformFilterOperator.STARTS_WITH]: FilterOperator.STARTS_WITH,
  [PlatformFilterOperator.ENDS_WITH]: FilterOperator.ENDS_WITH,
} as const;

export const platformFilterOperatorToFilterOperatorMapForDates: Partial<{
  [key in PlatformFilterOperator]: FilterOperator;
}> = {
  [PlatformFilterOperator.EQUALS]: FilterOperator.ON,
  [PlatformFilterOperator.NOT_EQUAL]: FilterOperator.NOT_ON,
  [PlatformFilterOperator.LESS_THAN]: FilterOperator.BEFORE,
  [PlatformFilterOperator.GREATER_THAN]: FilterOperator.AFTER,
  [PlatformFilterOperator.LESS_THAN_OR_EQUAL]: FilterOperator.ON_OR_BEFORE,
  [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]: FilterOperator.ON_OR_AFTER,
} as const;

export const platformCombineOperatorToFilterCombineOperator: {
  [key in PlatformCombineOperator]: CombineOperator;
} = {
  $and: "AND",
  $or: "OR",
} as const;

export const getRegularFieldConditionValue = (
  field: IField,
  condition: PlatformSimpleCondition
): FilterCondition | undefined => {
  if (!isNotEmpty(condition[field.platformFilterName])) {
    return undefined;
  }
  const platformCondition: Condition | AreaCondition | any = condition[field.platformFilterName];

  // remove $dateOnly field as we don't use it, but it interferes keys count calculations
  // used below in isInRangeCondition or in filterOperators.length > 1
  // Same for $offset
  if (typeof platformCondition === "object" && "$dateOnly" in platformCondition) {
    delete platformCondition.$dateOnly;
  }
  if (typeof platformCondition === "object" && "$offset" in platformCondition) {
    delete platformCondition.$offset;
  }

  const isDateField = [FieldType.DATE_TIME, FieldType.DATE].includes(field.type);

  if (typeof platformCondition !== "object" || platformCondition === null) {
    if (isDateField) {
      return { operator: FilterOperator.ON, value: platformCondition };
    }
    return field.type === FieldType.BOOLEAN
      ? {
          operator: !!platformCondition ? FilterOperator.IS_TRUE : FilterOperator.IS_FALSE,
          value: undefined,
        }
      : { operator: FilterOperator.EQUALS, value: platformCondition };
  }

  if (field.hasFeature(FieldFeature.FREQUENCY_STATUS_FIELD)) {
    return parseCadenceStatusCondition(platformCondition);
  }

  if (field.hasFeature(FieldFeature.ROTTING_DAYS_OUT_FIELD)) {
    return parseRottingDaysOutCondition(platformCondition);
  }

  if (isInRangeCondition(platformCondition)) {
    return parseRangeCondition(platformCondition);
  }

  if (isInAreaCondition(platformCondition)) {
    return parseAreaCondition(platformCondition);
  }

  if (isEmptyCondition(platformCondition)) {
    return parseEmptyCondition(platformCondition);
  }

  if (isLegacyCondition(platformCondition)) {
    return parseLegacyCondition(field, platformCondition);
  }

  if (isIntervalCondition(platformCondition)) {
    return parseIntervalCondition(platformCondition);
  }

  const filterOperator = Object.keys(platformCondition)[0] as PlatformFilterOperator;
  const value = platformCondition[filterOperator];

  switch (filterOperator) {
    case PlatformFilterOperator.GROUP_IN_ALL:
      return { operator: FilterOperator.IN_ALL, value };
    case PlatformFilterOperator.GROUP_IN_ANY:
      return { operator: SET_FILTER_OPERATOR, value };
    case PlatformFilterOperator.CONTAINS:
      return field.hasFeature(FieldFeature.SUPPORTS_SET_FILTER)
        ? { operator: SET_FILTER_OPERATOR, value }
        : { operator: FilterOperator.CONTAINS, value };
    case PlatformFilterOperator.NOT_CONTAINS:
      return field.hasFeature(FieldFeature.SUPPORTS_SET_FILTER)
        ? { operator: FilterOperator.NOT_IN, value }
        : { operator: FilterOperator.NOT_CONTAINS, value };
    case PlatformFilterOperator.EQUALS:
    case PlatformFilterOperator.NOT_EQUAL:
    case PlatformFilterOperator.LESS_THAN:
    case PlatformFilterOperator.GREATER_THAN:
    case PlatformFilterOperator.LESS_THAN_OR_EQUAL:
    case PlatformFilterOperator.GREATER_THAN_OR_EQUAL: {
      const operator = (
        isDateField
          ? platformFilterOperatorToFilterOperatorMapForDates
          : platformFilterOperatorToFilterOperatorMap
      )[filterOperator];
      invariant(operator, `Failed to convert "${filterOperator}" into platform operator`);
      return { operator, value };
    }
    case PlatformFilterOperator.STARTS_WITH:
    case PlatformFilterOperator.ENDS_WITH: {
      const operator = platformFilterOperatorToFilterOperatorMap[filterOperator];
      invariant(operator, `Failed to convert "${filterOperator}" into platform operator`);
      return { operator, value };
    }
  }

  return undefined;
};

export const getCustomFieldConditionValue = (
  field: IField,
  condition: PlatformSimpleCondition
): SimpleCondition | undefined => {
  if (!condition[field.platformFilterName]) {
    return undefined;
  }
  const isDateField = [FieldType.DATE_TIME, FieldType.DATE].includes(field.type);

  const platformCondition: Condition | any = condition[field.platformFilterName];

  // remove $dateOnly field as we don't use it, but it interferes keys count calculations
  // used below in isInRangeCondition or in filterOperators.length > 1
  // Same for $offset
  if (typeof platformCondition === "object" && "$dateOnly" in platformCondition) {
    delete platformCondition.$dateOnly;
  }
  if (typeof platformCondition === "object" && "$offset" in platformCondition) {
    delete platformCondition.$offset;
  }
  const filterOperators =
    typeof platformCondition !== "object" // if it is not an object, it must be a value itself, and the operator is EQ
      ? []
      : Object.keys(platformCondition)
          // skip dataType field, it's not an operator
          .filter((operator) => operator !== "dataType");

  if (isInRangeCondition(platformCondition)) {
    return parseRangeCondition(platformCondition);
  }

  if (isEmptyCondition(platformCondition)) {
    return parseEmptyCondition(platformCondition);
  }

  if (isIntervalCondition(platformCondition)) {
    return parseIntervalCondition(platformCondition);
  }

  invariant(
    filterOperators.length <= 1,
    `More than one filter operator for custom field condition: "${JSON.stringify(condition)}"`
  );
  const filterOperator = filterOperators.length ? filterOperators[0] : FilterOperator.EQUALS;
  const value = filterOperators.length
    ? (platformCondition as any)[filterOperator]
    : platformCondition;

  switch (filterOperator) {
    case PlatformFilterOperator.CONTAINS:
      return field.type === FieldType.LIST || field.hasFeature(FieldFeature.SUPPORTS_SET_FILTER)
        ? { operator: SET_FILTER_OPERATOR, value }
        : { operator: FilterOperator.CONTAINS, value };
    case PlatformFilterOperator.EQUALS:
      return field.type === FieldType.BOOLEAN
        ? {
            operator: !!value ? FilterOperator.IS_TRUE : FilterOperator.IS_FALSE,
            value: undefined,
          }
        : { operator: isDateField ? FilterOperator.ON : FilterOperator.EQUALS, value };
    case PlatformFilterOperator.NOT_EQUAL:
    case PlatformFilterOperator.LESS_THAN:
    case PlatformFilterOperator.GREATER_THAN:
    case PlatformFilterOperator.LESS_THAN_OR_EQUAL:
    case PlatformFilterOperator.GREATER_THAN_OR_EQUAL: {
      const operator = (
        isDateField
          ? platformFilterOperatorToFilterOperatorMapForDates
          : platformFilterOperatorToFilterOperatorMap
      )[filterOperator];
      invariant(operator, `Failed to convert "${filterOperator}" into platform operator`);
      return { operator, value };
    }
    case PlatformFilterOperator.NOT_CONTAINS:
      if (field.hasFeature(FieldFeature.SUPPORTS_SET_FILTER)) {
        if (field.hasFeature(FieldFeature.GROUP_FIELD)) {
          return { operator: FilterOperator.NOT_IN, value };
        } else {
          return { operator: FilterOperator.NONE_OF, value };
        }
      }
      return { operator: FilterOperator.NOT_CONTAINS, value };
    case PlatformFilterOperator.STARTS_WITH:
    case PlatformFilterOperator.ENDS_WITH: {
      const operator = platformFilterOperatorToFilterOperatorMap[filterOperator];
      invariant(operator, `Failed to convert "${filterOperator}" into platform operator`);
      return { operator, value };
    }
  }

  return undefined;
};

const ignoreFilters = [
  "includeNotes",
  "includeGroups",
  "includeCustomFields",
  "includeAccessStatus",
  "includeRoutes",
  "includeTerritories",
];

// Look for the value of the given filterName in conditions and nested conditions recursively
const findFieldValue = (
  conditions: PlatformFilterCondition[],
  isPresentInPlatformCondition: (condition: Record<string, unknown>) => boolean,
  combineOperator: PlatformCombineOperator
): PlatformFilterCondition | undefined => {
  // try simple approach first
  let values = conditions.filter((condition) => isPresentInPlatformCondition(condition as any));
  if (values.length) {
    return values.length > 1 ? { [combineOperator]: values } : values[0];
  }

  // if not found, try looking inside nested $and or $or, if they're present
  // $and takes precedence

  const andConditions: PlatformCombinedCondition | undefined = conditions.find(
    (condition) => (condition as PlatformCombinedCondition).$and
  );
  if (andConditions) {
    const value = findFieldValue(andConditions.$and!, isPresentInPlatformCondition, "$and");
    if (value) {
      return value;
    }
  }

  const orConditions: PlatformCombinedCondition | undefined = conditions.find(
    (condition) => (condition as PlatformCombinedCondition).$or
  );
  if (orConditions) {
    const value = findFieldValue(orConditions.$or!, isPresentInPlatformCondition, "$or");
    if (value) {
      return value;
    }
  }

  return undefined;
};

export const convertFromPlatformFilterModel = (
  platformFilterModel: PlatformFilterModel,
  fieldModel: IFieldModel,
  includeField: (field: IField) => boolean = () => true
): FilterModel => {
  // remove filters we're not interested in
  const model = { ...platformFilterModel };
  Object.keys(model).forEach((key) => {
    if (ignoreFilters.includes(key)) {
      delete model[key as keyof PlatformFilterModel];
    }
  });
  const filterModel: FilterModel = {};

  fieldModel.fields
    .filter((field) => field.hasAnyFeature(FieldFeature.FILTERABLE, FieldFeature.FILTERABLE_ON_MAP))
    .filter(includeField)
    .forEach((field) => {
      let value: PlatformFilterCondition | undefined;
      // look for value for current field
      if (isMetaField(field)) {
        // in the root of platform filter if field is a meta field
        if (field.isPresentInPlatformCondition(model)) {
          value = field.getFilterConditionForMetaField(platformFilterModel);
        }
      } else {
        // or inside $and or $or arrays otherwise
        value = findFieldValue(
          platformFilterModel.$and ?? [],
          field.isPresentInPlatformCondition.bind(field),
          "$and"
        );
        if (!value) {
          value = findFieldValue(
            platformFilterModel.$or ?? [],
            field.isPresentInPlatformCondition.bind(field),
            "$or"
          );
        }
      }

      // value not found, nothing to do here
      if (!value) {
        return;
      }

      const result = field.convertFromPlatformCondition(value);
      if (result) {
        filterModel[field.filterName] = result;
      }
    });

  return filterModel;
};
