import IField, { CsvExportProperties } from "@mapmycustomers/shared/types/fieldModel/IField";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import i18nService from "config/I18nService";
import { MessageDescriptor } from "react-intl";
import {
  defaultTooltipValueGetter,
  valueFormatterUsingFunction,
  valueGetterUsingFunction,
} from "./fieldUtil";
import isFunction from "lodash-es/isFunction";
import get from "lodash-es/get";
import isNil from "lodash-es/isNil";
import { ColDef } from "@ag-grid-community/core";
import {
  isCombinedCondition,
  isCombinedPlatformCondition,
  isSimpleCondition,
  isSimplePlatformCondition,
} from "util/viewModel/assert";
import {
  filterCombineOperatorToPlatformCombineOperator,
  getRegularFieldPlatformConditionValue,
} from "util/viewModel/convertToPlatformFilterModel";
import PlatformFilterModel, {
  PlatformFilterCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import FilterModel, {
  FilterCondition,
} from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import {
  getRegularFieldConditionValue,
  platformCombineOperatorToFilterCombineOperator,
} from "util/viewModel/convertFromPlatformFilterModel";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import { isFilterInstanceConfig, isFilterOperatorConfig } from "./assert";
import IFiltersConfig, {
  FilterInstanceType,
  FilterOptionValue,
  IFilterInstanceConfig,
  IHumanReadableFilterConfig,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import invariant from "tiny-invariant";
import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import defaultFilters from "util/filters/defaultFilters";
import { getFilterOperatorDisplayName } from "util/ui";
import IFieldModel from "@mapmycustomers/shared/types/fieldModel/IFieldModel";
import csvFormatterByType from "./csvFormatterByType";
import loggingService from "util/logging";
import SchemaField from "@mapmycustomers/shared/types/schema/SchemaField";
import SchemaFieldType from "@mapmycustomers/shared/enum/SchemaFieldType";
import ImportProperties from "@mapmycustomers/shared/types/fieldModel/ImportProperties";
import FormProperties from "@mapmycustomers/shared/types/fieldModel/FormProperties";
import SchemaFieldCategory from "@mapmycustomers/shared/enum/SchemaFieldCategory";

export type FieldValueGetter = string | string[] | ((entity: unknown, field: Field) => any);
export type FieldValueFormatter = (entity: unknown, value: unknown) => string;

export interface FieldProperties {
  csvExportProperties?: CsvExportProperties;
  customFilterConfig?: Partial<IHumanReadableFilterConfig>;
  customGridProperties?: ColDef;
  displayName: string | MessageDescriptor;
  displayOrder?: number;
  exportColumnName?: string;
  extraPlatformFiltersGetter?: () => PlatformFilterModel;
  features?: FieldFeature[];
  fieldManagementDisplayName?: MessageDescriptor;
  filterName?: string;
  filterType?: FieldType;
  formProperties?: FormProperties;
  importProperties?: ImportProperties;
  name: string;
  overridePhiSupport?: boolean;
  platformAreaFieldName?: string;
  platformFilterName?: string;
  platformName?: string;
  type: FieldType;
  valueFormatter?: FieldValueFormatter;
  valueGetter?: FieldValueGetter;
}

class Field implements IField {
  protected readonly _name: string;
  protected _filterName: string;
  protected _platformFilterName: string;
  protected _platformAreaFieldName: string | undefined;
  protected _platformName: string;
  protected _exportColumnName: string;
  protected readonly _displayName: string | MessageDescriptor;
  protected readonly _fieldManagementDisplayName?: MessageDescriptor;
  private readonly _displayOrder: undefined | number;
  private readonly _extraPlatformFiltersGetter: undefined | (() => PlatformFilterModel);
  protected readonly _type: FieldType;
  protected readonly _filterType: FieldType;
  protected readonly _valueFormatter: undefined | FieldValueFormatter;
  protected readonly _valueGetter: undefined | FieldValueGetter;
  protected readonly _importProperties?: ImportProperties;
  protected readonly _formProperties?: FormProperties;
  protected readonly _csvExportProperties?: CsvExportProperties;
  protected _features!: Set<FieldFeature>;
  protected _customGridProperties?: ColDef;
  protected _schemaDetails?: SchemaField;
  protected _filter!: false | IFiltersConfig;
  protected readonly _overridePhiSupport?: boolean;

  constructor(props: FieldProperties) {
    const {
      name,
      displayName,
      displayOrder,
      extraPlatformFiltersGetter,
      fieldManagementDisplayName,
      filterName,
      filterType,
      formProperties,
      overridePhiSupport,
      platformFilterName,
      platformAreaFieldName,
      platformName,
      exportColumnName,
      type,
      valueFormatter,
      valueGetter,
      importProperties,
      csvExportProperties,
      ...restProps
    } = props;
    this._name = name;
    this._filterName = filterName ?? name;
    this._platformFilterName = platformFilterName ?? filterName ?? name;
    this._platformAreaFieldName = platformAreaFieldName;
    this._platformName = platformName ?? name;
    this._exportColumnName = exportColumnName ?? name;
    this._displayName = displayName;
    this._fieldManagementDisplayName = fieldManagementDisplayName;
    this._displayOrder = displayOrder;
    this._extraPlatformFiltersGetter = extraPlatformFiltersGetter;
    this._type = type;
    this._filterType = filterType ?? type;
    this._valueFormatter = valueFormatter;
    this._valueGetter = valueGetter;
    this._importProperties = importProperties;
    this._formProperties = formProperties;
    this._csvExportProperties = csvExportProperties;
    this._overridePhiSupport = overridePhiSupport;
    this.update(restProps);
  }

  update(props: Omit<FieldProperties, "name" | "displayName" | "type">) {
    const { customFilterConfig, customGridProperties, features } = props;
    this._features = new Set(features);
    this._customGridProperties = customGridProperties;
    this._filter = this.processInitialFilterConfig(customFilterConfig);
  }

  setSchema(schemaDetails: SchemaField) {
    this._schemaDetails = schemaDetails;
  }

  get name(): string {
    return this._name;
  }

  get filterName(): string {
    return this._filterName;
  }

  get platformFilterName(): string {
    return this._platformFilterName;
  }

  get platformAreaFieldName(): string | undefined {
    return this._platformAreaFieldName;
  }

  get platformName(): string {
    return this._platformName;
  }

  get exportColumnName(): string {
    return this._exportColumnName;
  }

  get sortName(): string {
    return this.name;
  }

  get schemaDetails(): SchemaField | undefined {
    return this._schemaDetails;
  }

  get displayName(): string {
    return typeof this._displayName === "string"
      ? this._displayName // designed for using with Custom Fields
      : i18nService.getIntl()?.formatMessage(this._displayName) ?? "";
  }

  get displayOrder(): number | undefined {
    return this._displayOrder;
  }

  get filter(): IFiltersConfig | false {
    return this._filter;
  }

  get isGroupField(): boolean {
    return false;
  }

  get type(): FieldType {
    return this._type;
  }

  get filterType(): FieldType {
    return this._filterType;
  }

  get extraPlatformFilters(): PlatformFilterModel {
    return this._extraPlatformFiltersGetter ? this._extraPlatformFiltersGetter() : {};
  }

  get integrationName(): string {
    return this._name;
  }

  get formProperties(): FormProperties | undefined {
    return this._formProperties;
  }

  get importProperties(): ImportProperties | undefined {
    return this._importProperties;
  }

  get importName(): string {
    return this.importProperties?.name || this.name;
  }

  get fieldManagementDisplayName(): string {
    if (this._fieldManagementDisplayName) {
      return i18nService.getIntl()?.formatMessage(this._fieldManagementDisplayName) ?? "";
    }

    return this.displayName;
  }

  get importDisplayName(): string {
    if (this.importProperties?.displayName) {
      return i18nService.getIntl()?.formatMessage(this.importProperties.displayName) ?? "";
    }

    return this.displayName;
  }

  gridProperties(forClientSideGrid?: boolean): ColDef {
    return this.buildGridProperties(forClientSideGrid, this._customGridProperties);
  }

  hasFeature = (feature: FieldFeature): boolean => this._features.has(feature);
  hasAllFeatures = (...features: FieldFeature[]): boolean =>
    features.every((feature) => this._features.has(feature));
  hasAnyFeature = (...features: FieldFeature[]): boolean =>
    features.some((feature) => this._features.has(feature));

  toggleFeature = (feature: FieldFeature, present: boolean) => {
    if (present) {
      this._features.add(feature);
    } else {
      this._features.delete(feature);
    }
  };

  /**
   * Returns column's value from the given entity object
   * @param {unknown} entity
   * @returns {*}
   */
  getValueFor(entity: unknown): unknown {
    return isFunction(this._valueGetter)
      ? this._valueGetter(entity, this)
      : get(entity, this._valueGetter || this.name);
  }

  getFormattedValueFor(entity: unknown): string {
    const value = this.getValueFor(entity);
    return this.formatValue(entity, value);
  }

  hasNonEmptyValueFor(entity: unknown): boolean {
    const value = this.getValueFor(entity);
    return !(
      isNil(value) ||
      (Array.isArray(value) && !value.length) ||
      (typeof value === "string" && value.trim() === "")
    );
  }

  getHumanReadableDescription(
    value: FilterCondition | undefined,
    filterOptions?: Partial<Record<FilterOption, FilterOptionValue>>
  ): string | undefined {
    if (!value || !this._filter) {
      return undefined;
    }

    if (isCombinedCondition(value)) {
      const nestedConditionsDescriptions = value.conditions.map((condition) =>
        this.getHumanReadableDescription(condition, filterOptions)
      );
      // TODO: translate AND/OR operator
      return `(${nestedConditionsDescriptions.join(` ${value.operator} `)})`;
    }

    const operator = this._filter.operators.find((config) => config.operator === value.operator);
    if (!operator) {
      loggingService.error(`Unknown operator applied to field ${this.name}: "${value.operator}"`);
      return undefined;
    }

    const instance = this._filter.filterInstances[operator.instance];
    if (!instance) {
      loggingService.error(
        `Unknown instance referred in operator in field ${this.name}: "${operator.instance}"`
      );
      return undefined;
    }

    if (instance.instance.getHumanReadableDescription) {
      return instance.instance.getHumanReadableDescription(value, this, {
        ...filterOptions,
        ...instance.options,
      });
    }

    // use default filter formatting which is fieldName-operator-value
    const intl = i18nService.getIntl();
    const formattedOperator = intl
      ? getFilterOperatorDisplayName(intl, value.operator, this)
      : value.operator;
    const formattedValue = value.value ? ` ${String(value.value)}` : "";
    return `${this.displayName} ${formattedOperator}${formattedValue}`;
  }

  setFilterOption(option: FilterOption, value: FilterOptionValue) {
    if (!this._filter) {
      return;
    }

    Object.keys(this._filter.filterInstances).forEach((instanceName) => {
      const instance = (this._filter as IFiltersConfig).filterInstances[instanceName];
      if (instance.instance.doesSupportOption?.(option)) {
        instance.options = { ...(instance.options ?? {}), [option]: value };
      }
    });
  }

  isFilterEnabled(currentFilters: Partial<FilterModel>, fieldModel: IFieldModel): true | string {
    return true; // feel free to override in successors
  }

  convertToPlatformCondition(filterCondition: FilterCondition): PlatformFilterCondition {
    if (isCombinedCondition(filterCondition)) {
      const operator = filterCombineOperatorToPlatformCombineOperator[filterCondition.operator];
      return {
        [operator]: filterCondition.conditions.map((simpleCondition) => ({
          [this._platformFilterName]: getRegularFieldPlatformConditionValue(this, simpleCondition),
        })),
      };
    } else {
      return {
        [this._platformFilterName]: getRegularFieldPlatformConditionValue(this, filterCondition),
      };
    }
  }

  convertFromPlatformCondition(
    filterCondition: PlatformFilterCondition
  ): FilterCondition | undefined {
    if (isCombinedPlatformCondition(filterCondition)) {
      const platformOperator = "$and" in filterCondition ? "$and" : "$or";
      const operator = platformCombineOperatorToFilterCombineOperator[platformOperator];

      let conditions = filterCondition[platformOperator]!.filter(isSimplePlatformCondition) // we do not support nested combined conditions
        .map((simpleCondition) => getRegularFieldConditionValue(this, simpleCondition))
        .filter(isDefined);

      // only leave such combined conditions which have matching operator
      const combinedConditions = conditions
        .filter(isCombinedCondition)
        .filter((condition) => condition.operator === operator);
      combinedConditions.forEach((condition) => {
        conditions = conditions.concat(condition.conditions);
      });

      return { operator, conditions: conditions.filter(isSimpleCondition) };
    } else {
      return getRegularFieldConditionValue(this, filterCondition);
    }
  }

  isPresentInPlatformCondition(condition: Record<string, unknown>): boolean {
    return this.platformFilterName in condition;
  }

  getFilterConditionForMetaField(
    platformFilterModel: PlatformFilterModel
  ): PlatformFilterCondition {
    return platformFilterModel[this.platformFilterName as keyof PlatformFilterModel];
  }

  protected buildGridProperties(forClientSideGrid?: boolean, customGridProperties?: ColDef) {
    const gridProperties: ColDef = {
      colId: this.name,
      type: [String(this._type), ...Array.from(this._features)], // some column properties are set according to types, see Grid.tsx
      suppressMenu: false,
      unSortIcon: false,
      suppressSizeToFit: false,
      resizable: true,
      suppressAutoSize: false,
      editable: false,
      headerName: this.displayName,
      headerTooltip: this.displayName,
      menuTabs: ["generalMenuTab"],
      filter: false, // we do not filter in grid anymore
    };

    if (this._type === FieldType.NUMBER || this.hasFeature(FieldFeature.NUMERIC)) {
      gridProperties.cellClass = "mmc-text-align-right";
    }

    gridProperties.tooltipValueGetter = defaultTooltipValueGetter(this.name);
    gridProperties.valueGetter = valueGetterUsingFunction(this.getValueFor.bind(this));
    gridProperties.valueFormatter = valueFormatterUsingFunction(this.formatValue.bind(this));

    return Object.assign(gridProperties, customGridProperties);
  }

  /**
   * Returns formatted value from the given entity object and value.
   * @param {Object} entity
   * @param {*} value parsed value
   * @returns {string}
   */
  protected formatValue(entity: unknown, value: unknown): string {
    return this._valueFormatter ? this._valueFormatter(entity, value) : String(value ?? "");
  }

  // Convert given config filter into IFiltersConfig or false if filter is not given. Also, validate config.
  protected processInitialFilterConfig(
    customFilter?: Partial<IHumanReadableFilterConfig>
  ): false | IFiltersConfig {
    if (!this.hasFeature(FieldFeature.FILTERABLE) && !customFilter) {
      return false;
    }

    // not using deepmerge here to avoid merging unwanted props
    const filter: Partial<IHumanReadableFilterConfig> = {
      ...(defaultFilters[this._type] || {}),
      ...(customFilter || {}),
    };

    // no config after all, means no filter
    // TODO: consider converting this into invariant after all new filters are done
    // because basically, it should _either_ have default or custom filter or NOT have FILTERABLE feature
    if (!Object.keys(filter).length) {
      return false;
    }

    invariant(
      filter.filterInstances && Object.keys(filter.filterInstances).length > 0,
      `Filter instances are not defined for field "${this.name}"`
    );
    invariant(
      filter.operators && filter.operators.length > 0,
      `Filter operators are not defined for field "${this.name}"`
    );

    const instanceNames = Object.keys(filter.filterInstances);
    const defaultInstance = filter.defaultInstance ?? instanceNames[0]; // take first instance if default instance is not specified
    invariant(
      instanceNames.includes(defaultInstance),
      `Invalid default instance: "${defaultInstance}". It is not present in instances for field "${this.name}".`
    );

    // iterate over all instances to guarantee each item is of IFilterInstanceConfig type
    const instances: Record<FilterInstanceType, IFilterInstanceConfig> = instanceNames.reduce(
      (result, instanceName) => {
        const item = filter.filterInstances![instanceName];
        return {
          ...result,
          [instanceName]: isFilterInstanceConfig(item) ? item : { instance: item },
        };
      },
      {}
    );

    // iterate over all operators to guarantee
    const operators = filter.operators.map((operator) =>
      isFilterOperatorConfig(operator) ? operator : { operator, instance: defaultInstance }
    );

    let defaultOperator = operators[0].operator;
    if (filter.defaultOperator) {
      invariant(
        operators.some(({ operator }) => operator === filter.defaultOperator),
        `Invalid default operator: "${filter.defaultOperator}". Not present in operators list for field "${this.name}"`
      );
      defaultOperator = filter.defaultOperator;
    }

    return {
      defaultOperator,
      filterInstances: instances,
      operators,
    };
  }

  getFormattedCsvValueFor(entity: unknown) {
    const value = this.getValueFor(entity);

    const formatter = this._csvExportProperties?.valueFormatter ?? csvFormatterByType[this.type];
    if (formatter) {
      return formatter(value);
    }
    return this.formatValue(entity, value);
  }

  get isCustomizableField() {
    return !this.hasAnyFeature(
      FieldFeature.NON_LIST_VIEW,
      FieldFeature.NON_MAP_VIEW,
      FieldFeature.MAP_PINNED_FIELD
    );
  }

  // schema related getters:

  get isArchived() {
    return this._schemaDetails?.archived === true;
  }

  get doesSupportPhi() {
    if (this._overridePhiSupport !== undefined) {
      return this._overridePhiSupport;
    }
    if (
      this.hasAnyFeature(
        FieldFeature.RELATIONSHIPS,
        FieldFeature.GROUP_FIELD,
        FieldFeature.ACTIVITY_TYPE_FIELD,
        FieldFeature.FUNNEL_FIELD,
        FieldFeature.STAGE_FIELD
      ) ||
      [
        SchemaFieldCategory.SYSTEM_REQUIRED,
        SchemaFieldCategory.SYSTEM_DEPENDENT,
        SchemaFieldCategory.RELATIONSHIP,
        SchemaFieldCategory.GROUP,
        SchemaFieldCategory.VARIANT,
      ].includes(this.platformFieldCategory)
    ) {
      return false;
    }
    return true;
  }

  get isArchivable() {
    return (
      this._schemaDetails?.canArchive === true &&
      // platform currently returns canArchive=true for system-dependent fields, so we need this condition
      this.platformFieldCategory !== SchemaFieldCategory.SYSTEM_DEPENDENT
    );
  }

  get isCompound() {
    return this._schemaDetails?.compound === true;
  }

  get isDefaultValueAllowed() {
    return this._schemaDetails?.isDefaultValueAllowed === true;
  }

  get isEditable() {
    return this._schemaDetails?.accessStatus.update !== false;
  }

  get isEssential() {
    return [
      SchemaFieldCategory.SYSTEM_REQUIRED,
      SchemaFieldCategory.SYSTEM_DEPENDENT,
      SchemaFieldCategory.VARIANT,
    ].includes(this.platformFieldCategory);
  }

  get isMovableInLayout() {
    return !this.isEssential;
  }

  get isDeletableInLayout() {
    return !this.isEssential;
  }

  get isPhiEnabled() {
    return this._schemaDetails?.phiEnabled === true;
  }

  get isReadable() {
    // system-required fields are always readable
    if (this.isSystemRequired) {
      return true;
    }
    return this._schemaDetails?.accessStatus.read !== false;
  }

  get isSystemRequired() {
    return this.platformFieldCategory === SchemaFieldCategory.SYSTEM_REQUIRED;
  }

  get isUsedInCalculatedFields() {
    return this._schemaDetails?.usedInCalculatedFields === true;
  }

  get isUsedInWorkflow() {
    return this._schemaDetails?.usedInWorkflow === true;
  }

  get platformFieldType() {
    if (this._schemaDetails) {
      return this._schemaDetails.fieldType;
    }

    if (this.hasFeature(FieldFeature.CUSTOM_FIELD)) {
      return SchemaFieldType.CUSTOM;
    }

    if (this.hasFeature(FieldFeature.GROUP_FIELD)) {
      return SchemaFieldType.GROUP;
    }

    if (this.hasFeature(FieldFeature.FILE_FIELD)) {
      return SchemaFieldType.FILE;
    }

    return SchemaFieldType.STANDARD;
  }

  get platformFieldCategory() {
    if (this._schemaDetails?.category) {
      return this._schemaDetails.category;
    }

    if (this.hasFeature(FieldFeature.CUSTOM_FIELD)) {
      return SchemaFieldCategory.CUSTOM;
    }

    if (this.hasFeature(FieldFeature.GROUP_FIELD)) {
      return SchemaFieldCategory.GROUP;
    }

    if (this.hasFeature(FieldFeature.FILE_FIELD)) {
      return SchemaFieldCategory.FILE;
    }

    if (this.hasFeature(FieldFeature.ADDRESS)) {
      return SchemaFieldCategory.ADDRESS;
    }

    if (this.hasFeature(FieldFeature.RELATIONSHIPS)) {
      return SchemaFieldCategory.RELATIONSHIP;
    }

    if (
      this.hasAnyFeature(
        FieldFeature.FUNNEL_FIELD,
        FieldFeature.STAGE_FIELD,
        FieldFeature.ACTIVITY_TYPE_FIELD
      )
    ) {
      return SchemaFieldCategory.VARIANT;
    }

    return SchemaFieldCategory.STANDARD;
  }
}

export default Field;
