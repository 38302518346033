import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";

const companyRecordState = (state: RootState) => state.component.companyRecord;

export const getRecordData = createSelector(companyRecordState, ({ recordData }) => recordData);

export const isLoading = createSelector(companyRecordState, ({ loading }) => loading);

export const isDeleting = createSelector(companyRecordState, ({ deleteLoading }) => deleteLoading);

export const isCompanyUpdateLoading = createSelector(
  companyRecordState,
  ({ companyUpdateLoading }) => companyUpdateLoading
);

export const isActivitiesLoading = createSelector(
  companyRecordState,
  ({ activitiesLoading }) => activitiesLoading
);

export const isActivitiesCompletedByTypeLoading = createSelector(
  companyRecordState,
  ({ activitiesCompletedByTypeLoading }) => activitiesCompletedByTypeLoading
);

export const isActivitiesTotalLoading = createSelector(
  companyRecordState,
  ({ activitiesTotalLoading }) => activitiesTotalLoading
);

export const isAddCompanyChildCompaniesLoading = createSelector(
  companyRecordState,
  ({ addCompanyChildCompaniesLoading }) => addCompanyChildCompaniesLoading
);

export const isAddCompanyDealsLoading = createSelector(
  companyRecordState,
  ({ addCompanyDealsLoading }) => addCompanyDealsLoading
);

export const isAddCompanyParentCompanyLoading = createSelector(
  companyRecordState,
  ({ addCompanyParentCompanyLoading }) => addCompanyParentCompanyLoading
);

export const isAddCompanyPersonsLoading = createSelector(
  companyRecordState,
  ({ addCompanyPersonsLoading }) => addCompanyPersonsLoading
);

export const isCompanyRoutesLoading = createSelector(
  companyRecordState,
  ({ companyRoutesLoading }) => companyRoutesLoading
);

export const isCompanyNoteCreateLoading = createSelector(
  companyRecordState,
  ({ companyNoteCreateLoading }) => companyNoteCreateLoading
);

export const areFilesUploading = createSelector(
  companyRecordState,
  ({ filesUploading }) => filesUploading
);

export const getSelectedActivityTypes = createSelector(
  companyRecordState,
  ({ activitiesSelectedActivityTypes }) => activitiesSelectedActivityTypes
);

export const getRecapRange = createSelector(
  companyRecordState,
  ({ activitiesRecapRange }) => activitiesRecapRange
);

export const getActiveTab = createSelector(companyRecordState, ({ activeTab }) => activeTab);

export const getActivitiesSearchQuery = createSelector(
  companyRecordState,
  ({ activitiesSearchQuery }) => activitiesSearchQuery
);

export const getActivitiesFilter = createSelector(
  companyRecordState,
  ({ activitiesFilter }) => activitiesFilter
);

export const getActivitiesOrder = createSelector(
  companyRecordState,
  ({ activitiesOrder }) => activitiesOrder
);

export const getActivitiesSelectedAssignees = createSelector(
  companyRecordState,
  ({ activitiesSelectedAssignees }) => activitiesSelectedAssignees
);

export const getRemoveCompanyRouteLoadingsIds = createSelector(
  companyRecordState,
  ({ removeCompanyRouteLoadingsIds }) => removeCompanyRouteLoadingsIds
);

export const getCompanyNoteDeleteLoadingIds = createSelector(
  companyRecordState,
  ({ companyNoteDeleteLoadingIds }) => companyNoteDeleteLoadingIds
);

export const getCompanyNoteUpdateLoadingIds = createSelector(
  companyRecordState,
  ({ companyNoteUpdateLoadingIds }) => companyNoteUpdateLoadingIds
);
export const getPostponeActivityLoadingIds = createSelector(
  companyRecordState,
  ({ postponeActivityLoadingIds }) => postponeActivityLoadingIds
);

export const getToggleCompleteActivityLoadingIds = createSelector(
  companyRecordState,
  ({ toggleCompleteActivityLoadingIds }) => toggleCompleteActivityLoadingIds
);

export const getUpdateActivityNoteLoadingIds = createSelector(
  companyRecordState,
  ({ updateActivityNoteLoadingIds }) => updateActivityNoteLoadingIds
);

export const doesNeedToScrollToActivity = createSelector(
  companyRecordState,
  ({ activityAutoScrolling }) => activityAutoScrolling
);

export const isCompanyRecordChanged = createSelector(
  companyRecordState,
  ({ hasChanges }) => hasChanges
);

export const getCompany = createSelector(getRecordData, ({ company }) => company);
