import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import UserSelectField from "component/input/UserSelectField";
import User from "@mapmycustomers/shared/types/User";
import styles from "./AssigneeSearch.module.scss";

interface Props {
  allowedUserEmails?: string[];
  disabled?: boolean;
  onChange?: (assignee?: User) => void;
  users: User[];
  value?: User;
}

const AssigneeSearch: React.FC<Props> = ({
  allowedUserEmails,
  disabled,
  onChange,
  users,
  value,
}) => {
  const intl = useIntl();

  const userFilterOption = useCallback(
    (inputValue: string, option: any) => {
      const user = users.find(({ id }) => id === parseInt(option?.value));
      return ((user?.fullName ?? "") + (user?.username ?? ""))
        .toLowerCase()
        .includes(inputValue.toLowerCase().trim());
    },
    [users]
  );

  const filteredUsers = useMemo(
    () =>
      allowedUserEmails
        ? users.filter(({ username }) => allowedUserEmails.includes(username))
        : users,
    [allowedUserEmails, users]
  );

  useEffect(() => {
    value &&
      allowedUserEmails &&
      !allowedUserEmails.includes(value.username) &&
      onChange?.(undefined);
  }, [allowedUserEmails, value, onChange]);

  const handleUserSelect = useCallback(
    (value: number) => {
      const user = users.find(({ id }) => id === value);
      if (user) {
        onChange?.(user);
      }
    },
    [onChange, users]
  );

  return (
    <UserSelectField
      className={styles.select}
      defaultUsersOption={false}
      disabled={disabled}
      dropdownMatchSelectWidth={false}
      filterOption={userFilterOption}
      label={false}
      onSelect={handleUserSelect}
      placeholder={intl.formatMessage({
        id: "createEditActivityModal.field.assignee.placeholder",
        defaultMessage: "Type to find user by name or email",
        description: "Placeholder of the Assignee field on the Create Activity modal",
      })}
      showSearch
      users={filteredUsers}
      value={value}
    />
  );
};

export default AssigneeSearch;
