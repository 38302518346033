import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import Button from "antd/es/button";
import Form from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import Tooltip from "antd/es/tooltip";
import File from "./components/File";
import { PersonPayload } from "store/api/ApiService";
import BaseModal from "component/modal/BaseModal";
import { FormItem, LoadingSpinner, TextField } from "@mapmycustomers/ui";
import DuplicatesTable from "component/DuplicatesTable";
import FormFields from "component/FormFields";
import ConditionalFormField, {
  ConditionalFormFieldRenderProps,
} from "component/ConditionalFormField";
import FormLayoutSelector from "component/FormLayoutSelector";
import FormNoLayoutAlert from "component/FormNoLayoutAlert";
import GeocodeLimitField from "../components/GeocodeLimitField";
import PreviewModeIndicator from "component/PreviewModeIndicator";
import { RootState } from "store/rootReducer";
import { getUploadedFilesList, isCreating, isInitializing } from "./store";
import { clearAllUploadedPersonFiles, createPerson, initialize } from "./store/actions";
import FileListItem from "types/FileListItem";
import { Company, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import { getDuplicates, isGlobalAddPerson } from "./store/selectors";
import FormValues from "./types/FormValues";
import { CompanyRef } from "@mapmycustomers/shared/types/entity/Company";
import { DuplicateEntity } from "@mapmycustomers/shared/types/entity/Duplicate";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import useErroredFieldsScroller from "util/hook/useErroredFieldsScroller";
import { showGlobalPostCreationModal } from "component/PostCreationModal/store/actions";
import useFormChangeTracker from "util/hook/form/useFormChangeTracker";
import getInitialValues from "./util/getInitialValues";
import { getAddress } from "../util/getAddress";
import { PersonFieldName } from "util/fieldModel/PersonFieldModel";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import { personLayoutModel } from "util/layout/impl";
import PersonCompanyField from "component/FormFields/components/ParentCompanyField/PersonCompanyField";
import AddressField from "component/FormFields/components/AddressField";
import User from "@mapmycustomers/shared/types/User";
import { getCurrentUserId } from "store/iam";

interface OwnProps {
  className?: string;
  fixedCompany?: Company;
  initialValues?: Partial<Person>;
  okText?: ReactNode;
  onHide: (newPerson?: Person) => void;
  showPostCreationModal?: boolean;
}

interface Props extends OwnProps {
  clearAllUploadedFiles: () => void;
  createPerson: typeof createPerson.request;
  creating: boolean;
  currentUserId: User["id"];
  duplicates: DuplicateEntity[];
  initialize: (payload: { fixedCompany?: CompanyRef }) => void;
  initializing: boolean;
  initialValues?: Partial<Person>;
  isGlobalAddPerson?: boolean;
  okText?: ReactNode;
  onHide: (newPerson?: Person) => void;
  onShowGlobalPostCreationModal: typeof showGlobalPostCreationModal;
  previewLayout?: FormLayout;
  showPostCreationModal?: boolean;
  uploadedFiles: FileListItem[];
}

const CreatePersonModal: React.FC<Props> = ({
  className,
  clearAllUploadedFiles,
  createPerson,
  creating,
  currentUserId,
  duplicates,
  fixedCompany,
  initialize,
  initializing,
  initialValues,
  okText,
  onHide,
  onShowGlobalPostCreationModal,
  previewLayout,
  showPostCreationModal,
  uploadedFiles,
}) => {
  const intl = useIntl();

  const [formLayout, setFormLayout] = useState(personLayoutModel.defaultFormLayout);

  const [form] = useForm<FormValues>();
  const [isFormChanged, handleFormChanges] = useFormChangeTracker(form);

  useEffect(() => {
    initialize({ fixedCompany });
  }, [fixedCompany, initialize]);

  const handleVisibility = useCallback(
    (person: Person) => {
      onHide(person);
      if (showPostCreationModal) {
        onShowGlobalPostCreationModal({ entity: person, entityType: EntityType.PERSON });
      }
    },
    [showPostCreationModal, onHide, onShowGlobalPostCreationModal]
  );

  const handleCancelClick = useCallback(() => {
    clearAllUploadedFiles();
    onHide();
  }, [clearAllUploadedFiles, onHide]);
  const handleOkClick = useCallback(() => form.submit(), [form]);
  const handleCreate = useCallback(
    (checkDuplicates = true) => {
      const layoutId = formLayout?.id;

      if (layoutId) {
        const values = form.getFieldsValue();
        const address = getAddress(values, initialValues?.geoManagementState);

        const firstName = values?.firstName ?? "";
        const lastName = values?.lastName ?? "";

        const person: PersonPayload = {
          ...address,
          accounts: values.accounts ?? [],
          color: values.color,
          email: values.email,
          firstName,
          geoAddress: initialValues?.geoAddress ?? address.geoAddress,
          geoPoint: initialValues?.geoPoint ?? address.geoPoint,
          lastName,
          name: `${firstName} ${lastName}`,
          phone: values.phone,
          user: values.user ? { id: values.user } : undefined,
        };

        createPerson({
          callback: handleVisibility,
          checkDuplicates,
          customFieldsValues: values.customFields
            ? Object.values(values.customFields).filter(isDefined)
            : [],
          groupsIdsToAdd: values.groups ? Array.from(values.groups) : [],
          layoutId,
          person,
        });
      }
    },
    [createPerson, form, formLayout, handleVisibility, initialValues]
  );

  const [parentCompanies, setParentCompanies] = useState<Company[]>(
    fixedCompany ? [fixedCompany] : []
  );

  const isPreviewMode = !!previewLayout;
  const currentLayout = isPreviewMode ? previewLayout : formLayout;
  const [formContainer, handleFinishFail] = useErroredFieldsScroller();

  const submitButtonDisabled = creating || initializing || isPreviewMode || !currentLayout;
  const showConfirm =
    !isPreviewMode && !initializing && (isFormChanged || uploadedFiles.length > 0);
  const hasDuplicates = duplicates.length > 0;

  return (
    <BaseModal
      borderedHeader
      className={className}
      entityTypeName="Person"
      footer={
        hasDuplicates ? null : (
          <>
            <Tooltip
              placement="topRight"
              title={
                isPreviewMode
                  ? intl.formatMessage({
                      id: "createPersonModal.footer.previewMode.tooltip",
                      defaultMessage: "Please exit Preview Mode to create person",
                      description: "Create Person modal - OK button tooltip in preview mode",
                    })
                  : null
              }
              trigger={isPreviewMode ? ["hover"] : []}
            >
              <Button
                disabled={submitButtonDisabled}
                loading={creating}
                onClick={handleOkClick}
                type="primary"
              >
                {okText ??
                  intl.formatMessage({
                    id: "createPersonModal.footer.ok",
                    defaultMessage: "Create Person",
                    description: "Create button title on Create Person modal",
                  })}
              </Button>
            </Tooltip>
          </>
        )
      }
      onCancel={handleCancelClick}
      onOk={handleOkClick}
      showConfirm={showConfirm}
      title={
        <>
          {intl.formatMessage({
            id: "createPersonModal.title",
            defaultMessage: "Create Person",
            description: "Title of Create Person modal",
          })}

          {isPreviewMode ? (
            <PreviewModeIndicator />
          ) : (
            <FormLayoutSelector
              disabled={creating || initializing}
              entityType={EntityType.PERSON}
              layout={formLayout}
              onChange={setFormLayout}
            />
          )}
        </>
      }
    >
      {initializing ? (
        <LoadingSpinner />
      ) : currentLayout ? (
        <>
          {hasDuplicates ? (
            <DuplicatesTable
              duplicates={duplicates}
              entityType={EntityType.PERSON}
              onCreate={() => handleCreate(false)}
            />
          ) : null}

          <div hidden={!!duplicates.length} ref={formContainer}>
            <GeocodeLimitField />

            <Form
              form={form}
              initialValues={getInitialValues(currentUserId, initialValues, fixedCompany)}
              layout="vertical"
              onFinish={handleCreate}
              onFinishFailed={handleFinishFail}
              onValuesChange={handleFormChanges}
              preserve
            >
              <FormFields
                entityType={EntityType.PERSON}
                fileComponent={File}
                isCreateForm
                layout={currentLayout}
              >
                <ConditionalFormField fieldName={PersonFieldName.PHONE}>
                  {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                    <FormItem
                      label={label}
                      name={PersonFieldName.PHONE}
                      required={required}
                      rules={[{ required }]}
                    >
                      <TextField
                        disabled={disabled}
                        label={label}
                        placeholder={label?.toString()}
                        required={required}
                      />
                    </FormItem>
                  )}
                </ConditionalFormField>

                <ConditionalFormField fieldName={PersonFieldName.ACCOUNTS}>
                  {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                    <PersonCompanyField
                      allowAdd={!disabled}
                      disabled={disabled}
                      onChange={setParentCompanies}
                      required={required}
                    />
                  )}
                </ConditionalFormField>

                <ConditionalFormField feature={FieldFeature.ADDRESS}>
                  {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                    <AddressField
                      disabled={disabled}
                      inheritAddressFrom={parentCompanies[0]}
                      required={required}
                    />
                  )}
                </ConditionalFormField>
              </FormFields>
            </Form>
          </div>
        </>
      ) : (
        <FormNoLayoutAlert />
      )}
    </BaseModal>
  );
};

const mapStateToProps = (state: RootState, { showPostCreationModal }: OwnProps) => ({
  creating: isCreating(state),
  currentUserId: getCurrentUserId(state)!,
  duplicates: getDuplicates(state),
  initializing: isInitializing(state),
  showPostCreationModal: showPostCreationModal ?? isGlobalAddPerson(state),
  uploadedFiles: getUploadedFilesList(state),
});

const mapDispatchToProps = {
  clearAllUploadedFiles: clearAllUploadedPersonFiles,
  createPerson: createPerson.request,
  initialize: initialize.request,
  onShowGlobalPostCreationModal: showGlobalPostCreationModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePersonModal);
